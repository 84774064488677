define('ember-tracker/-privates/utils', ['exports', 'ember'], function (exports, _ember) {
  exports.getCurrentRoute = getCurrentRoute;

  /**
   * Returns the route.
   * @public
   * @type {Function}
   * @return {Route}
   */

  function getCurrentRoute(context, routeName) {
    return _ember['default'].getOwner(context).lookup('route:' + routeName);
  }

  /**
   * Supports <= 2.4 with merge otherwise it chooses assign.
   * @public
   * @type {Function}
   */
  var mergeObjects = _ember['default'].assign || _ember['default'].merge;

  exports.mergeObjects = mergeObjects;
  /**
   * Find out if we're in fastboot.
   * @public
   * @type {Boolean}
   */
  var IN_BROWSER = !!window && !!window.document;

  exports.IN_BROWSER = IN_BROWSER;
  /**
   * Merge or assign.
   * @public
   * @type {Function}
   */
  var mergeOrAssign = _ember['default'].assign || _ember['default'].merge;
  exports.mergeOrAssign = mergeOrAssign;
});
/*global window document*/