define("ember-link-to-wrapper/components/link-to-wrapper", ["exports", "ember-link-to-wrapper/templates/components/link-to-wrapper"], function (exports, _linkToWrapper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    layout: _linkToWrapper.default,
    tagName: 'li',

    _inlineFormBody: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    }),

    _inlineFormParams: Ember.computed('params.[]', function () {
      return this.get('params').slice(1);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      // the click event is handled by the inner link-to,
      // otherwise we would transition twice
      this.off(this.get('eventName'));
    }
  });
});