define('ember-tracker/mixins/tealium-route', ['exports', 'ember', 'ember-tracker/-privates/utils'], function (exports, _ember, _emberTrackerPrivatesUtils) {
	var getWithDefault = _ember['default'].getWithDefault;
	var DEFAULT_VIEW = {
		customerId: null,
		domain: getWithDefault(window || {}, 'location.hostname', ''),
		order_currency: 'USD',
		page_type: 'home'
	};

	exports.DEFAULT_VIEW = DEFAULT_VIEW;
	var run = _ember['default'].run;
	var typeOf = _ember['default'].typeOf;
	var testing = _ember['default'].testing;
	exports['default'] = _ember['default'].Mixin.create({
		/**
   * Sets the utag.
   * @public
   * @memberOf {Mixin.TealiumRoute}
   * @override
   * @return {undefined}
   */
		init: function init() {
			this.setProperties({
				_etLastView: null,
				_tealium: null
			});

			this._super.apply(this, arguments);

			if (!testing && _emberTrackerPrivatesUtils.IN_BROWSER) {
				this._etCheckForUtag();
			}
		},

		/**
   * Checks for the utag param on the window and sets it. if there was a previous call to transition, send it.
   * @private
   * @memberOf {TealiumRoute}
    * @return {undefined}
    */
		_etCheckForUtag: function _etCheckForUtag() {
			var _this = this;

			run(function () {
				return _this.set('_tealium', window && window.utag);
			});

			if (this.get('_tealium')) {
				var lastView = this.get('_etLastView');
				if (lastView) {
					this.get('_tealium').view(lastView);
				}
				return;
			}

			// Run this later if they are using onLoad instead of inserting immediately
			run.later(this, '_etCheckForUtag', 500);
		},

		/**
   * Returns the route required.
   * @private
   * @memberOf {TealiumRoute}
   * @return {Route}
   */
		_etGetCurrentRoute: function _etGetCurrentRoute(routeName) {
			return (0, _emberTrackerPrivatesUtils.getCurrentRoute)(this, routeName);
		},

		/**
   * Watches the transition event and sends a new view to Tealium.
   * @private
   * @memberOf {Mixin.TealiumRoute}
   * @observer
   * @return {undefined}
   */
		_etTealium: _ember['default'].on('didTransition', function () {
			var routeName = this.get('currentRouteName'),
			    route = this._etGetCurrentRoute(routeName),
			    hasTealiumFn = typeOf(route.getTealiumView) === 'function',
			    utag = this.get('_tealium'),
			    currView = {};

			_ember['default'].assert(hasTealiumFn, routeName + ' route doesn\'t have a "getTealiumView" function');

			(0, _emberTrackerPrivatesUtils.mergeObjects)(currView, DEFAULT_VIEW);

			if (hasTealiumFn) {
				(0, _emberTrackerPrivatesUtils.mergeObjects)(currView, route.getTealiumView());
			}

			if (utag) {
				utag.view(currView);
			} else {
				this.set('_etLastView', currView);
			}
		})
	});
});